export const stocksSearch = [
  { ticker: "TSLA", company: "Tesla" },
  { ticker: "AAPL", company: "Apple" },
  { ticker: "AMZN", company: "Amazon.com" },
  { ticker: "MSFT", company: "Microsoft Corp" },
  { ticker: "NVDA", company: "NVIDIA Corp." },
  { ticker: "GOOG", company: "Alphabet" },
  { ticker: "PEP", company: "PepsiCo" },
  { ticker: "COST", company: "Costco Wholesale Corp" },
  { ticker: "CMCSA", company: "Comcast Corp" },
  { ticker: "ADBE", company: "Adobe" },
  { ticker: "TXN", company: "Texas Instruments" },
  { ticker: "AVGO", company: "Broadcom" },
  { ticker: "HON", company: "Honeywell International" },
  { ticker: "INTC", company: "Intel Corp" },
  { ticker: "TMUS", company: "T-Mobile US" },
  { ticker: "SBUX", company: "Starbucks Corp." },
  { ticker: "NFLX", company: "Netflix" },
  { ticker: "QCOM", company: "QUALCOMM" },
  { ticker: "AMD", company: "Advanced Micro Devices" },
  { ticker: "CSCO", company: "Cisco Systems" },
  { ticker: "INTU", company: "Intuit" },
  { ticker: "AMGN", company: "Amgen" },
  { ticker: "AMAT", company: "Applied Materials" },
  { ticker: "GILD", company: "Gilead Sciences" },
  { ticker: "MDLZ", company: "Mondelez International" },
  { ticker: "ADI", company: "Analog Devices" },
  { ticker: "ADP", company: "Automatic Data Processing" },
  { ticker: "ISRG", company: "Intuitive Surgical" },
  { ticker: "REGN", company: "Regeneron Pharmaceuticals" },
  { ticker: "PYPL", company: "PayPal Holdings" },
  { ticker: "VRTX", company: "Vertex Pharmaceuticals " },
  { ticker: "FISV", company: "Fiserv" },
  { ticker: "LRCX", company: "Lam Research Corp" },
  { ticker: "ATVI", company: "Activision Blizzard" },
  { ticker: "MU", company: "Micron Technology" },
  { ticker: "MELI", company: "MercadoLibre" },
  { ticker: "CSX", company: "CSX Corp" },
  { ticker: "PANW", company: "Palo Alto Networks" },
  { ticker: "MRNA", company: "Moderna " },
  { ticker: "SNPS", company: "Synopsys " },
  { ticker: "ASML", company: "ASML Holding NV" },
  { ticker: "CDNS", company: "Cadence Design Systems" },
  { ticker: "CHTR", company: "Charter Communications" },
  { ticker: "KLAC", company: "KLA Corp" },
  { ticker: "ORLY", company: "O'Reilly Automotive" },
  { ticker: "FTNT", company: "Fortinet" },
  { ticker: "KDP", company: "Keurig Dr Pepper" },
  { ticker: "MAR", company: "Marriott International MD" },
  { ticker: "ABNB", company: "Airbnb " },
  { ticker: "KHC", company: "Kraft Heinz Co/The" },
  { ticker: "AEP", company: "American Electric Power Co" },
  { ticker: "NXPI", company: "NXP Semiconductors NV" },
  { ticker: "DXCM", company: "Dexcom" },
  { ticker: "CTAS", company: "Cintas Corp" },
  { ticker: "ADSK", company: "Autodesk" },
  { ticker: "PDD", company: "PDD Holdings ADR" },
  { ticker: "MCHP", company: "Microchip Technology" },
  { ticker: "AZN", company: "AstraZeneca PLC ADR" },
  { ticker: "IDXX", company: "IDEXX Laboratories" },
  { ticker: "EXC", company: "Exelon Corp" },
  { ticker: "PAYX", company: "Paychex" },
  { ticker: "BIIB", company: "Biogen" },
  { ticker: "LULU", company: "Lululemon Athletica" },
  { ticker: "WDAY", company: "Workday" },
  { ticker: "SGEN", company: "Seagen" },
  { ticker: "PCAR", company: "PACCAR" },
  { ticker: "GFS", company: "GLOBALFOUNDRIES" },
  { ticker: "ODFL", company: "Old Dominion Freight Line" },
  { ticker: "XEL", company: "Xcel Energy" },
  { ticker: "MRVL", company: "Marvell Technology" },
  { ticker: "WBD", company: "Warner Bros Discovery" },
  { ticker: "CPRT", company: "Copart" },
  { ticker: "ROST", company: "Ross Stores" },
  { ticker: "ILMN", company: "Illumina" },
  { ticker: "EA", company: "Electronic Arts" },
  { ticker: "DLTR", company: "Dollar Tree" },
  { ticker: "CTSH", company: "Cognizant Technology Solutions Corp" },
  { ticker: "FAST", company: "Fastenal Co" },
  { ticker: "CRWD", company: "Crowdstrike Holdings" },
  { ticker: "VRSK", company: "Verisk Analytics" },
  { ticker: "WBA", company: "Walgreens Boots Alliance" },
  { ticker: "CSGP", company: "CoStar Group" },
  { ticker: "ANSS", company: "ANSYS" },
  { ticker: "BKR", company: "Baker Hughes Co" },
  { ticker: "MNST", company: "Monster Beverage Corp" },
  { ticker: "ENPH", company: "Enphase Energy" },
  { ticker: "CEG", company: "Constellation Energy Corp" },
  { ticker: "FANG", company: "Diamondback Energy" },
  { ticker: "ALGN", company: "Align Technology" },
  { ticker: "TEAM", company: "Atlassian Corp" },
  { ticker: "EBAY", company: "eBay" },
  { ticker: "DDOG", company: "Datadog" },
  { ticker: "JD", company: "JD.com  ADR" },
  { ticker: "ZM", company: "Zoom Video Communications" },
  { ticker: "ZS", company: "Zscaler" },
  { ticker: "LCID", company: "Lucid Group" },
  { ticker: "SIRI", company: "Sirius XM Holdings" },
  { ticker: "RIVN", company: "Rivian Automotive" },
];
